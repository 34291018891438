


















import Vue, { PropType } from 'vue';

import ButtonIcon from '@/components/lh-ui/ButtonIcon/ButtonIcon.vue';
import AddInput from '@/components/lh-ui/AddInput/AddInput.vue';

export default Vue.extend({
  name: 'NewFavoriteFolder',
  components: {
    ButtonIcon,
    AddInput,
  },
  props: {
    mode: {
      type: String as PropType<'button' | 'input'>,
      default: 'button',
    },
    buttonText: {
      type: String as PropType<string>,
      default: 'Добавить папку',
    },
    themeAddInput: {
      type: String as PropType<string>,
      default: '',
    },
  },
  data: () => ({
    folderName: '',
    defaultNameFolder: 'Новая папка',
    maxLengthNameFolder: 50, // ограничение на сервере для имени папки избранного - 100
    // 50 - как временно решение: https://youtrack.lifehacker.ru/issue/LH-1040
  }),

  watch: {
    mode (newMode: 'button' | 'input') {
      if (newMode === 'button') {
        this.folderName = '';
      }
    },
  },

  methods: {
    changeMode (mode: string): void {
      this.$emit('update:mode', mode);
    },
    onInputEnter (nameFolder: string): void {
      this.$emit('create-folder', nameFolder || this.defaultNameFolder);
    },
    onInputEscape (): void {
      this.folderName = '';
      this.changeMode('button');
    },
  },
});
