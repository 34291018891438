





import Vue, { PropType } from 'vue';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

export default Vue.extend({
  name: 'ScrollContainer',
  components: {
    PerfectScrollbar,
  },
  props: {
    active: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    options: {
      type: Object as PropType<object>,
      default: () => ({}),
    },
  },
  watch: {
    active (newValue) {
      if (newValue) {
        this.updatePerfectScrollbar();
      }
    },
  },
  mounted (): void {
    this.updatePerfectScrollbar();
  },
  methods: {
    updatePerfectScrollbar () {
      // Если элемент изначально не отображается, то скроллбар не может рассчитать свои размеры.
      // Поэтому при появлении элемента на экране нужно вызвать метод update.
      // См issue https://github.com/mdbootstrap/perfect-scrollbar/issues/476
      // и документацию библиотеки perfect-scrollbar
      try {
        // @ts-ignore
        this.$refs.perfectScrollbar.update();
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    },
  },
});
