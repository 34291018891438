













// для отладки можно запускать в хроме с отключенным CORS
// eslint-disable-next-line max-len
// open -n -a /Applications/Google\ Chrome.app/Contents/MacOS/Google\ Chrome --args --user-data-dir="/tmp/chrome_dev_test" --disable-web-security
import Vue, { PropType } from 'vue';
import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';

export default Vue.extend({
  name: 'ArticleCardCategoryIcon',
  components: {
    TheLinkUi,
  },
  props: {
    icon: {
      type: String as PropType<string>,
      required: true,
    },
    href: {
      type: String as PropType<string>,
      default: null,
    },
    name: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    target: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    size: {
      type: Number as PropType<number>,
      default: 32,
    },
    color: {
      type: String as PropType<string>,
      default: '#ED5E42',
    },
    colorHover: {
      type: String as PropType<string>,
      default: 'white',
    },
    background: {
      type: String as PropType<string>,
      default: 'white',
    },
    backgroundHover: {
      type: String as PropType<string>,
      default: '#ED5E42',
    },
  },
  computed: {
    isIcon (): boolean {
      return Boolean(this.icon);
    },
    isLink (): boolean {
      return Boolean(this.href);
    },
    iconClasses (): any {
      return {
        'lh-article-card-category-icon': true,
        'lh-article-card-category-icon--link': this.isLink,
      };
    },
    iconStyles (): any {
      return {
        '--mask-image': this.icon ? `url('${this.icon}')` : null,
        '--size': `${this.size}px`,
        '--color': this.color,
        '--colorHover': this.colorHover,
        '--background': this.background,
        '--backgroundHover': this.backgroundHover,
      };
    },
    type (): any {
      return this.isLink ? TheLinkUi : 'div';
    },
  },
});
