


import Vue, { PropType } from 'vue';
import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';

import TheLink from '~/components/TheLink.vue';

export default Vue.extend({
  name: 'TheSliderItem',

  components: {
    TheLink,
    AppPicture,
  },

  props: {
    link: {
      type: String as PropType<string>,
      required: true,
    },

    title: {
      type: String as PropType<string>,
      required: true,
    },

    images: {
      type: Array as PropType<IArticleImg[]>,
      default: () => [],
    },

    defaultImg: {
      type: Array as PropType<IArticleImg[]>,
      default: () => [],
    },

    imgAlt: {
      type: String as PropType<string>,
      required: true,
    },

    target: {
      type: String as PropType<string>,
      default: null,
    },
  },
});
