





































import Vue, { PropType } from 'vue';
import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';
import FavoriteBlock from '@/components/lh-ui/FavoriteBlock/FavoriteBlock.vue';

import articleCardMixin from '@/mixins/ArticleCard';
import FavoriteButton from '@/components/lh-ui/buttons/FavoriteButton.vue';

export default Vue.extend({
  name: 'ArticleCardControls',
  components: {
    FavoriteButton,
    FavoriteBlock,
    TheLinkUi,
  },
  mixins: [articleCardMixin],
  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
    commentsEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    commentsCount: {
      type: Number as PropType<number>,
      required: false,
      default: 0,
    },
    color: {
      type: String as PropType<string>,
      required: false,
      default: '#969698',
    },
    hoverColor: {
      type: String as PropType<string>,
      required: false,
      default: 'black',
    },
    colorText: {
      type: String as PropType<string>,
      required: false,
      default: '#5C5C5C',
    },
    colorTextHover: {
      type: String as PropType<string>,
      required: false,
      default: 'black',
    },
    user: {
      type: Object as PropType<IUser | null>,
      default: null,
    },
    favorite: {
      required: false,
      type: Object as PropType<IFavoriteItem | null>,
      default: null,
    },
    favoriteMenuOffsets: {
      type: Object as PropType<TOffsets | null>,
      default: null,
    },
    folders: {
      required: true,
      type: Object as PropType<IFolders>,
    },
    isShowFavoriteFolderName: {
      type: Boolean,
      default: false,
    },
    internalUrl: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data: () => ({
    activeCardControls: false,
  }),
  computed: {
    isHasComments (): boolean {
      return this.commentsCount > 0;
    },
    controlsClass (): any {
      return this.activeCardControls ? 'lh-article-card-controls--active' : null;
    },
    controlsStyles (): any {
      return {
        '--base-color': this.color,
        '--hover-color': this.hoverColor,
        '--base-color-text': this.colorText,
        '--hover-color-text': this.colorTextHover,
      };
    },
  },
  methods: {
    onOpenFavoriteMenu (): void {
      this.activeCardControls = true;
    },
    onCloseFavoriteMenu (): void {
      this.activeCardControls = false;
    },
    onFavoriteAction (): void {
      this.$emit('favorite-action');
    },
    onClickComments (link: string): void {
      this.$emit('clicked-on-comments', link);
    },
  },

});
