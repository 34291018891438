




















import Vue from 'vue';

export default Vue.extend({
  name: 'TransitionLayout',
  props: {
    name: {
      type: String,
      default: 'fade',
    },
    mode: {
      type: String,
      default: 'out-in',
    },
    beforeEnter: {
      type: Function,
      default: null,
    },
    beforeLeave: {
      type: Function,
      default: null,
    },
    beforeAppear: {
      type: Function,
      default: null,
    },
    enter: {
      type: Function,
      default: null,
    },
    leave: {
      type: Function,
      default: null,
    },
    appear: {
      type: Function,
      default: null,
    },
    afterEnter: {
      type: Function,
      default: null,
    },
    afterLeave: {
      type: Function,
      default: null,
    },
    afterAppear: {
      type: Function,
      default: null,
    },
    enterCancelled: {
      type: Function,
      default: null,
    },
    leaveCancelled: {
      type: Function,
      default: null,
    },
    appearCancelled: {
      type: Function,
      default: null,
    },
  },
  methods: {
    onBeforeEnter (): void {
      if (typeof this.beforeEnter === 'function') {
        this.beforeEnter();
      }
    },
    onBeforeLeave (): void {
      if (typeof this.beforeLeave === 'function') {
        this.beforeLeave();
      }
    },
    onBeforeAppear (): void {
      if (typeof this.beforeAppear === 'function') {
        this.beforeAppear();
      }
    },
    onEnter (): void {
      if (typeof this.enter === 'function') {
        this.enter();
      }
    },
    onLeave (): void {
      if (typeof this.leave === 'function') {
        this.leave();
      }
    },
    onAppear (): void {
      if (typeof this.appear === 'function') {
        this.appear();
      }
    },
    onAfterEnter (): void {
      if (typeof this.afterEnter === 'function') {
        this.afterEnter();
      }
    },
    onAfterLeave (): void {
      if (typeof this.afterLeave === 'function') {
        this.afterLeave();
      }
    },
    onAfterAppear (): void {
      if (typeof this.afterAppear === 'function') {
        this.afterAppear();
      }
    },
    onEnterCancelled (): void {
      if (typeof this.enterCancelled === 'function') {
        this.enterCancelled();
      }
    },
    onLeaveCancelled (): void {
      if (typeof this.leaveCancelled === 'function') {
        this.leaveCancelled();
      }
    },
    onAppearCancelled (): void {
      if (typeof this.appearCancelled === 'function') {
        this.appearCancelled();
      }
    },
  },
});
