










import Vue, { PropType } from 'vue';
import { TSportsArticle } from '~/utils/sports';

export default Vue.extend({
  name: 'ArticleCardSimple',
  props: {
    article: {
      required: true,
      type: Object as PropType<TSportsArticle>,
    },

    target: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },

  computed: {
    getTarget (): string {
      return this.article.domain === 'lifehacker.ru' ? '_self' : '_target';
    },
  },

  methods: {
    sendAnalytic () {
      this.$emit('click-simple-article', this.article);
    },
  },
});
